import React from "react";
import { Breadcrumb } from "react-bootstrap";

export default function Breadcrumbs({children}) {
    return (
        <Breadcrumb>
            <Breadcrumb.Item href="https://makrealty.com">Home</Breadcrumb.Item>
            {
                children.map((child, index) => {
                    if (index === children.length - 1) {
                        return (
                            <Breadcrumb.Item active key={child.text}>
                                {child.text}
                            </Breadcrumb.Item>
                        );
                    } else {
                        return (
                            <Breadcrumb.Item href={child.url} key={child.text}>
                                {child.text}
                            </Breadcrumb.Item>
                        );
                    }
                })
            }
        </Breadcrumb>
    );
}
