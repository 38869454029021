import React, {Component} from 'react';
import {Badge, Card, Col, Row} from "react-bootstrap";
import { Link,withRouter } from 'react-router-dom';
import './TableCard.scss';
import starIcon from "../../../assets/icons/icon-star-full-white.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus, faEye, faMoneyBill, faRulerCombined, faUsers} from "@fortawesome/free-solid-svg-icons";
import StarRating from '../../Rating/StarRating';

const FONTAINEBLEAUAirBnbIds = ['65945f74b273840010e5b720', '65905db152cfea0011326616', '65dc2cbaf21f99001383f4a3']

class ListingCard
    extends Component {

    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
        this.handleRedirectCheckout= this.handleRedirectCheckout.bind(this)
    };

    handleClick(e) {
        this.props.onClick(e, this.props.data);
    }

    handleRedirect(e) {
        const { data, checkIn, checkOut, adults, history, children } = this.props;
        const basePath = `/listing/${data.id}/${FONTAINEBLEAUAirBnbIds.includes(data.airbnb_id) ? 'fontainebleau/' : ''}`;
        const query = `?checkIn=${checkIn}&checkOut=${checkOut}&adults=${adults}&children=${children}`;
        history.push(basePath + query);
    }

    handleRedirectCheckout(e) {
        e.preventDefault(); 
        const { data, checkIn, checkOut, adults, children, history } = this.props;
        const basePath = `/checkout/${data.id}`;
        const query = `?checkIn=${checkIn}&checkOut=${checkOut}&adults=${adults}&children=${children}`;
        window.location.href = (basePath + query);
    }

    render() {

        const {
                  data,
                  actions,
                  user,
                  checkIn,
                  checkOut,
                  adults,
                  children,
                  toggleFunction,
                  rating,
              } = this.props;

        var cardImage = (
            data.files.length ? data.files[0].url : ""
        );

        var subTotal = (
            data.price_array ? data.price_array.subtotal.toLocaleString('en-Us', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }) : 0
        );

        return (

            <Col onClick={this.handleRedirect} xs={12} sm={12} md={6} lg={6} xl={4} xxl={4}>
                <Card className={"tableCard"} onClick={this.handleClick} id={data.id}>

                    <Link
                        to={`/listing/${data.id}/${FONTAINEBLEAUAirBnbIds.includes(
                            data.airbnb_id) ? 'fontainebleau/' : ''}?checkIn=${checkIn}&checkOut=${checkOut}&adults=${adults}&children=${children}`}
                        className="tableCardHeader"
                        style={{background: "url('" + cardImage + "')"}}>
                        <div>
                            <div>
                                <Badge bg={"unset"} className={"ms-2 coupon-badge"}>SAVE</Badge>
                            </div>
                            <div>
                                {data.tagline ? data.tagline : "Fontainebleau Resort"}
                                <StarRating
                                    rating={rating}
                                    starIcon={starIcon}
                                />
                            </div>
                        </div>
                    </Link>

                    <div className={"tableCardBody"}>

                        <h1 className={"mb-3 tableCardBodyTitle"}>{data.name} {(
                            user && (
                                user.email == "eddy@beetechy.com" || user.email == "mk@makrealty.com"
                            ) ? <small>{data.nickname}</small> : <></>
                        )}</h1>

                        <Row className={"mb-4 tableCardMeta mt-5"}>
                            <Col xs={4} className={"pe-0"}>
                                <FontAwesomeIcon icon={faUsers} className={"me-1 me-lg-2"}/>
                                {data.number_of_guests} <small>guests</small>
                            </Col>
                            <Col xs={4} className={"pe-0"}>
                                <FontAwesomeIcon icon={faRulerCombined} className={"me-1 me-lg-2"}/>
                                {data.square_feet ? data.square_feet.toLocaleString() : 'N/A'} <small>sqft</small>
                            </Col>
                            <Col xs={4} className={"pe-0"} style={{paddingLeft:"3px"}} >
                                <FontAwesomeIcon icon={faMoneyBill} className={"me-1 me-lg-2"}/>
                                {(
                                    data.price_array ? new Intl.NumberFormat('en-US', {
                                        style:                 'currency',
                                        currency:              'USD',
                                        maximumFractionDigits: 0
                                    }).format(data.price_array.average.replace(',', '')) : 0
                                )}
                                <small> / nt.</small>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={6}>
                                <div
                                    className={"price lh-1 fs-4 fw-500 text-white"}>${subTotal}</div>
                                <div className={"price-subtitle"}>(taxes not incl.)</div>
                            </Col>
                            <Col xs={6} className="text-right justify-content-end d-flex">
                                <Link variant={"transparent"} size={"md"}
                                      className={"btn rounded-circle me-3 btn-transparent"}
                                      to={"/listing/" + data.id + '?checkIn=' + checkIn + '&checkOut=' + checkOut + '&adults=' + adults + '&children' + children}>
                                    <FontAwesomeIcon icon={faEye}/>
                                </Link>
                                <Link onClick={this.handleRedirectCheckout} className={"btn btn-primary rounded-circle"}>
                                    <FontAwesomeIcon icon={faCartPlus}/>
                                </Link>
                            </Col>
                        </Row>

                    </div>

                </Card>
            </Col>
        )
    }

}

export default withRouter(ListingCard);