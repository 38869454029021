import React, {Component} from 'react';
import {Button, Col, Container, Form, FormControl, FormGroup, FormLabel, Row, Spinner} from "react-bootstrap";
import Api from "../../Api";
import {Link, Redirect} from "react-router-dom";
import {FormError} from "../../components/Forms/Inputs/FormError";
import {toast} from "react-hot-toast";

export class Register
    extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formData:          [],
            formErrors:        [],
            formProcessing:    false,
            formSuccess:       false,
            show2fa:           false,
            showResetPassword: false,
        }

        this.handleSubmit        = this.handleSubmit.bind(this);
        this.handleInputChange   = this.handleInputChange.bind(this);
        this.toggleResetPassword = this.toggleResetPassword.bind(this);
    }

    toggleResetPassword() {
        this.setState({showResetPassword: !this.state.showResetPassword});
    }

    handleInputChange(event) {
        const target = event.target;
        const value  = target.type === 'checkbox' ? target.checked : target.value;
        const name   = target.name;

        let {
                formData   = [],
                formErrors = [],
                data       = []
            } = this.state;

        formData[name]   = value;
        formErrors[name] = null;
        data[name]       = value;

        this.setState({
                          formData:   formData,
                          formErrors: formErrors,
                          data:       data
                      })
    }

    handleSubmit(event) {

        event.preventDefault();

        let formData = new FormData();
        for (const key in
            this.state.formData) formData.append(key, this.state.formData[key]);
        this.setState({formProcessing: true});

        Api.post('/register', formData).then((response) => {

            toast.success("You have successfully registered. You may now log in.");
            this.setState({formSuccess: true});

        }).catch(error => {
            // handle error
            console.log(error);
            if (error.response && error.response.data) {
                toast.error(error.response.data.message);
                this.setState({
                                  formErrors: error.response.data.errors
                              })
            }
        }).finally(() => {
            this.setState({formProcessing: false});
        })
    }

    render() {

        if (this.props.user) {
            return <Redirect
                to={"/"}/>
        } else if (this.state.formSuccess) {
            return <Redirect
                to={"/login"}/>
        } else {
            return (<Container>

                <Row>
                    <Col>
                        <h1 className={"page-title"}>Register</h1>
                    </Col>
                </Row>

                <Row className="loginPage m-0">
                    <Col md={3}></Col>
                    <Col md={6} className={"p-0"}>
                        {/*<div className="loginLogo">*/}
                        {/*    <img src={logoWhite} alt={""}/>*/}
                        {/*</div>*/}
                        <div className="loginFormContainer align-middle">
                            <h6 className={"mb-4"}>Please enter your account information below.</h6>
                            <Form onSubmit={this.handleSubmit}>

                                <Row>
                                    <Col>
                                        <FormGroup className="mb-4">
                                            <FormLabel>Email</FormLabel>
                                            <FormControl
                                                type="email"
                                                name="email"
                                                required
                                                isInvalid={(this.state.formErrors && this.state.formErrors.email)}
                                                placeholder="Enter email"
                                                onChange={this.handleInputChange}
                                            />
                                            <FormError error={this.state.formErrors.email}/>
                                        </FormGroup>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col>
                                        <FormGroup className="mb-4">
                                            <FormLabel>First Name</FormLabel>
                                            <FormControl
                                                required
                                                type="text"
                                                name="first_name"
                                                isInvalid={(this.state.formErrors && this.state.formErrors.first_name)}
                                                placeholder="First name"
                                                onChange={this.handleInputChange}
                                            />
                                            <FormError error={this.state.formErrors.first_name}/>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup className="mb-4">
                                            <FormLabel>Last Name</FormLabel>
                                            <FormControl
                                                required
                                                type="text"
                                                name="last_name"
                                                isInvalid={(this.state.formErrors && this.state.formErrors.last_name)}
                                                placeholder="Last name"
                                                onChange={this.handleInputChange}
                                            />
                                            <FormError error={this.state.formErrors.last_name}/>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup className="mb-4">
                                            <FormLabel>Password</FormLabel>
                                            <FormControl
                                                required
                                                type="password"
                                                name="password"
                                                isInvalid={(this.state.formErrors && this.state.formErrors.password)}
                                                placeholder="Enter password"
                                                onChange={this.handleInputChange}
                                            />
                                            <FormError error={this.state.formErrors.password}/>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup className="mb-4">
                                            <FormLabel>Confirm Password</FormLabel>
                                            <FormControl
                                                required
                                                type="password"
                                                name="password_confirmation"
                                                isInvalid={(this.state.formErrors && this.state.formErrors.confirm_password)}
                                                placeholder="Confirm password"
                                                onChange={this.handleInputChange}
                                            />
                                            <FormError error={this.state.formErrors.confirm_password}/>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Button
                                            size="md"
                                            variant="primary"
                                            type="submit"
                                            className="m-auto mb-3 w-100"
                                            disabled={this.state.formProcessing}
                                        >
                                            {this.state.formProcessing && this.props.token ? <><Spinner size={"sm"}
                                                                                                        className={"me-2"}
                                                                                                        animation={"border"}/> Signing
                                                Up</> : (this.state.formProcessing ? <><Spinner size={"sm"}
                                                                                                className={"me-2"}
                                                                                                animation={"border"}/> Signing
                                                Up</> : "Sign Up")}
                                        </Button>

                                        <Link
                                            to={"/login"}
                                            className={"m-auto btn-md btn-secondary btn w-100"}>
                                            Log In
                                        </Link>
                                    </Col>
                                </Row>

                            </Form>
                        </div>
                    </Col>
                    <Col md={3}></Col>
                    {/*<Col className={"p-0"}>*/}
                    {/*    <div className="loginWelcomeText">*/}
                    {/*        <div>*/}
                    {/*            <h4>MAK Realty Group</h4>*/}
                    {/*            <h1>Welcome Back!</h1>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </Row>
            </Container>)
        }
    }
}