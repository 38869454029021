import React, {Component} from 'react';

export class FormError extends Component {

    render() {
        return (
            <>
                <p className={"mt-3 mb-0 text-danger" + (!this.props.error ? " d-none" : "")}>
                    {this.props.error}
                </p>
            </>
        )
    }
}