import React from "react";
import secureCheckout from "../../../assets/images/secure-checkout.png";

export default function PaymentInfo ({setTermsAccepted, termsAccepted}) {
    return (
        <div className="payment-info">
            <img className={"my-4 w-100"} src={secureCheckout}/>  <p className={"policy"}>Your
            personal data will be used to process your order, support your experience throughout this website,
            and for other
            purposes described in our privacy policy.</p> <p className={"policy"}><b>Cancellation</b>: Get a
            100% refund if
            you cancel at least 5 days before your check in date. Get a 50% refund if you cancel within 5 days.
            No refund is
            given on the day of check in or after.</p>
            <div className={"d-flex mt-4"}>
                <input checked={termsAccepted} required type={"checkbox"} id={"terms"} onChange={(e) => setTermsAccepted(e.target.checked)}/>
                <label htmlFor={"terms"}>I have read and agree to
                the <a href={"https://makrealty.com/terms-and-conditions"} target={"_blank"}>terms & conditions</a> and the cancellation
                policy. *</label>
            </div>
        </div>
    )
}