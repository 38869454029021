import React from 'react';

import atlantic from '../../../assets/images/atlantic.png';
import rest from '../../../assets/images/rest.png';
import treatment from '../../../assets/images/treatment.jpeg';
import club from '../../../assets/images/club.png';
import neighborhood from '../../../assets/images/neighborhood.png';
import hakkasan from '../../../assets/images/hakkasan.jpeg';
import miami from '../../../assets/images/miami.png';

import { ReactComponent as CarFront } from '../../../assets/icons/carFront.svg'
import { ReactComponent as CarTop } from '../../../assets/icons/carTop.svg'
import { ReactComponent as Lotus } from '../../../assets/icons/lotus.svg'
import { ReactComponent as SuteCase } from '../../../assets/icons/suteCase.svg'
import { ReactComponent as Bike } from '../../../assets/icons/bike.svg'
import { ReactComponent as Umbrella } from '../../../assets/icons/umbrella.svg'

import restorant from '../../../assets/images/restorant.png';
import lambo from '../../../assets/images/lambo.png'
import stadium from '../../../assets/images/stadium.png'
import waiter from '../../../assets/images/waiter.png';
import pool from '../../../assets/images/pool.png';

import hotelView from '../../../assets/images/hotelview.png'
import viewWithWomen from '../../../assets/images/cityViewWithWomen.jpg'
import roomPhoto from '../../../assets/images/roomview.png'
import hotelLook from '../../../assets/images/hotelPool.png'

import oasis1 from '../../../assets/images/oasis1.jpg'
import oasis2 from '../../../assets/images/oasis2.jpg'
import oasis3 from '../../../assets/images/oasis3.jpg'
import oasis4 from '../../../assets/images/oasis4.jpg'
import oasis5 from '../../../assets/images/oasis5.jpg'
import oasis6 from '../../../assets/images/oasis6.jpg'
import oasis7 from '../../../assets/images/oasis7.jpg'
import oasis8 from '../../../assets/images/oasis8.jpg'
import oasis9 from '../../../assets/images/oasis9.jpg'
import oasis10 from '../../../assets/images/oasis10.jpg'
import oasis11 from '../../../assets/images/oasis11.jpg'
import oasis12 from '../../../assets/images/oasis12.jpg'
import oasis13 from '../../../assets/images/oasis13.jpg'
import oasis14 from '../../../assets/images/oasis14.jpg'
import oasis15 from '../../../assets/images/oasis15.jpg'
import oasis16 from '../../../assets/images/oasis16.jpg'
import oasis17 from '../../../assets/images/oasis17.jpg'
import oasis18 from '../../../assets/images/oasis18.jpg'
import oasis19 from '../../../assets/images/oasis19.jpg'
import oasis20 from '../../../assets/images/oasis20.jpg'
import oasis21 from '../../../assets/images/oasis21.jpg'

import {
    faAirFreshener,
    faBathtub,
    faBed,
    faBowlFood,
    faCoffee,
    faDog,
    faDumbbell,
    faEarthOceania,
    faFaucet,
    faFireBurner,
    faGlobe,
    faIceCream,
    faParking,
    faSmoking,
    faSwimmingPool,
    faTelevision,
    faUtensilSpoon,
    faWarning,
    faWind,
    faHouseLaptop,
    faUtensils,
    faElevator,
    faMugHot,
    faPumpSoap,
    faKitchenSet,
    faSquare,
    faUmbrellaBeach
} from "@fortawesome/free-solid-svg-icons";

export const OASISCAROUSELPHOTOS = [
    oasis1,
    oasis2,
    oasis3,
    oasis4,
    oasis5,
    oasis6,
    oasis7,
    oasis8,
    oasis9,
    oasis10,
    oasis11,
    oasis12,
    oasis13,
    oasis14,
    oasis15,
    oasis16,
    oasis17,
    oasis18,
    oasis19,
    oasis20,]

export const CARUSELDATA = [
    {
        imgMobile: viewWithWomen,
        imgSrc: viewWithWomen,
        title: "Unveiling MAK’s Pinnacle of Luxury",
        text: "The FontaineBleau Tresor Penthouse"
    },
    {
        imgSrc: hotelLook,
        title: "A WORLD OF EXQUISITE COMFORT",
        text: "premium services and deluxe amenities "
    },
    {
        imgSrc: roomPhoto,
        title: "THE LUXURIOUS SANCTUARY",
        text: "save the date. we’ll do the rest."
    },
    {
        imgSrc: hotelView,
        title: "A TRANQUIL TROPICAL PARADISE",
        text: "where peace meets luxury"
    },
    {
        imgSrc: oasis21,
        title: "where the sea meets the sky",
        text: "the most majestic view in the city"
    },
]

export const AMENITIES = {
    'Property Details': {
        "swimming_pool": faSwimmingPool,
        "pets_allowed": faDog,
        "gym": faDumbbell,
        "elevator": faElevator,
        "free_parking_on_premises": faParking,
        "near_ocean": faEarthOceania,
        "beach_access": faUmbrellaBeach,
    },
    'Comfort': {
        "wireless_internet": faGlobe,
        "Flat-screen TV, wi-fi, minibar": faTelevision,
        "bed_linens": faBed,
        "laptop_friendly_workspace": faHouseLaptop,
    },
    'Bathroom': {
        "shampoo": faAirFreshener,
        "washer": faFaucet,
        "hair_dryer": faWind,
        "bathtub": faBathtub,
        "shower_gel": faPumpSoap,
    },
    'Kitchen & Dining': {
        "coffee_maker": faCoffee,
        "cookware": faUtensilSpoon,
        "microwave": faBowlFood,
        "oven": faFireBurner,
        "dishwasher": faFaucet,
        "Breakfast at lounge": faUtensils,
        "refrigerator": faIceCream,
        "coffee": faMugHot,
        "mini_fridge": faSquare,
        "outdoor_kitchen": faKitchenSet
    },
    'Privacy and safety': {
        "carbon_monoxide_detector": faWarning,
        "smoke_detector": faSmoking,
    },
    'other': {
        'Patio_or_balcony': null,
        'essentials': null,
        'hangers': null
    }
}

export const EXCLUSIVES = [
    {
        cn: 'left',
        img: restorant,
        title: 'The most upscale restaurants',
        text: "Explore world of fine dining at the city's most upscale restaurants."
    },
    {
        cn: 'right',
        img: lambo,
        title: 'Exotic cars',
        text: "Rent an exotic car or a yacht to complement your stay."
    },
    {
        cn: 'left',
        img: stadium,
        title: 'Local games',
        text: "Attend the local sports games and events."
    },
    {
        cn: 'right',
        img: waiter,
        title: 'Customisable service',
        text: "Customise your stay for a special occasion."
    },
    {
        cn: 'left',
        img: pool,
        title: 'Full access to Fontainebleau amenities',
        text: "Access to 7 pools with bar and beach restauraunt for you and special pool with water slide for your kids."
    },

]

export const EXTRAS = [
    {
        svg: <CarFront />,
        text: 'Private limo airport transfer to and from the suite'
    },
    {
        svg: <CarTop />,
        text: 'Valet parking for two vehicles  </br> for the entirety  </br> of your stay'
    }, {
        svg: <Lotus />,
        text: 'Two 50-minute complimentary spa treatments at Lapis Spa'
    }, {
        svg: < Umbrella />,
        text: 'Access to all Fontainebleau resort amenities'
    },
    {
        svg: <SuteCase />,
        text: 'Private rooftop pool and cabana use'
    },
    {
        svg: <Bike />,
        text: 'Free bike rental for 6 hours total'
    },
];

export const SECTIONS = [
    {
        img: atlantic,
        title: 'Nestled along the edge of the Atlantic',
        description: "the coastal haven offers a seamless extension of the penthouse's luxury."
    },
    {
        img: rest,
        title: 'have a royal rest you deserve',
        description: 'modern interiors and high classroom service'
    },
    {
        img: treatment,
        title: 'INDULGE IN exclusive treatment',
        description: 'our premium services are right at your fingertips'
    },
    {
        img: club,
        title: 'ELEVATE YOUR EVENING AT LIV CLUB',
        description: `SEE WHAT MIAMI'S FINEST NIGHTSPOT HAS TO OFFER`
    },
    {
        img: neighborhood,
        title: 'A Vibrant and Diverse Neighborhood',
        description: 'reflection of the rich history and diversity of Miami, </br> with influences from Latin America, Europe, and the Caribbean',
        descriptionNoBR: 'reflection of the rich history and diversity of Miami, with influences from Latin America, Europe, and the Caribbean',
    },
    {
        img: hakkasan,
        title: 'HAKKASAN: AUTHENTIC CHINESE CUISINE ',
        description: 'WORLD CLASS DINING right AT the resort'
    },
    {
        img: miami,
        title: 'panoramic views of Miami skyline',
        description: 'Live on top the world'
    },
]

export const STARTTOURS = [
    {
        id: "65905db152cfea0011326616",
        iframeSrc: "https://my.matterport.com/show/?m=yRT4kq1RQYa",
        side: "SOUTH",
        price: "3,759",
        sqft: "4500",
        rooms: "4",
        baths: "5.5",
        sqm:"418"
    },
    {
        id: "65945f74b273840010e5b720",
        iframeSrc: "https://my.matterport.com/show/?m=za3jeBY4dYt",
        side: "NORTH",
        price: "3,759",
        sqft: "4500",
        rooms: "5",
        baths: "5.5",
        sqm: "418"
    }
]