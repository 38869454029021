import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AMENITIES } from '../constants';

const Amenities = () => {
  return (
    <div className='amenities'>
      <h2 className='amenities-title'>amenities</h2>
      {Object.entries(AMENITIES).map(([categoryName, amenities]) => (
        <><p className='amenities-list-title'>{categoryName}</p><ul className='amenities-list'>
          {Object.entries(amenities).map(([amenityKey, icon]) => (
            <li key={amenityKey}>
              <div className='amenities-list-item'>
                <FontAwesomeIcon className='amenities-list-item-icon' icon={icon} />
                <p className='amenities-list-item-text'>{amenityKey.replace(/_/g, " ")}</p>
              </div>
            </li>
          ))}
        </ul></>
      ))}
    </div>
  )
}

export default Amenities