import React, {useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutPaymentStripeElement from "./CheckoutPaymentStripeElement";
import Api from "../../../Api";
import {loadStripe} from "@stripe/stripe-js";

const stripePromise = loadStripe((
                                     process.env.REACT_APP_DEBUG === "true" ? process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY : process.env.REACT_APP_STRIPE_LIVE_PUBLIC_KEY
                                 ));

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

function CheckoutPaymentStripe(props) {

    const [clientSecret, setClientSecret]       = useState("");
    const [paymentIntentId, setPaymentIntentId] = useState("");

    useEffect(() => {

        // Create PaymentIntent as soon as the page loads

        const queryParameters = new URLSearchParams(window.location.search);
        const checkIn         = queryParameters.get("checkIn");
        const checkOut        = queryParameters.get("checkOut");
        const adults          = queryParameters.get("adults");
        const children        = queryParameters.get("children") || 0;
        const referral_url    = getCookie("referral_url");

        Api.post('/api/stripe/intent', {
            checkIn:      checkIn,
            checkOut:     checkOut,
            adults:       adults,
            children:     children,
            listingId:    props.listingId,
            referral_url: referral_url,
            subtotal:     props.subtotal
        }).then((response) => {
            setClientSecret(response.data.clientSecret);
            setPaymentIntentId(response.data.paymentIntentId);
            props.provideIntentId(response.data.paymentIntentId)
        }).catch((error) => {
            props.setPaymentError(error);
        });
    }, []);

    const appearance = {
        theme:     'flat',
        variables: {
            fontFamily:      ' "Poppins", sans-serif',
            fontLineHeight:  '1.5',
            borderRadius:    '10px',
            colorBackground: 'transparent',
            colorPrimary:    '#ee4b81',
            colorText:       '#DDDDDD',
            colorLogo:       "dark",
        },
        rules:     {
            '.AccordionItem':                                                                                                                                             {
                backgroundColor: "transparent",
                borderColor:     "rgba(255, 255, 255, 0)"

            },
            '.AccordionItem--selected':                                                                                                                                   {
                color: "white",

            },
            '.AccordionButton':                                                                                                                                           {
                borderWidth: "0px"
            },
            '.Block':                                                                                                                                                     {
                background: 'transparent !important',
                boxShadow:  'none',
                padding:    '12px',
            },
            '.BlockAction':                                                                                                                                               {
                color: "white"
            },
            '.Input, .Input:disabled':                                                                                                                                    {
                padding:         '20px',
                backgroundColor: 'rgba(239, 240, 247, 0.05)',
                border:          '1px solid rgba(255, 255, 255, 0.5)',
                color:           'white',
                marginBottom:    "8px",
                letterSpacing:   "1px"
            },
            '.Input:hover':                                                                                                                                               {
                borderColor: "#fff"
            },
            '.Input::placeholder':                                                                                                                                        {
                color: '#6c757d'
            },
            '.Input--invalid':                                                                                                                                            {
                boxShadow:   "unset",
                borderColor: "#dc3545",
                color:       "#dc3545"
            },
            '.Error':                                                                                                                                                     {
                color:         "#f25a69",
                letterSpacing: "1px"
            },
            '.Input:disabled, .Input--invalid:disabled':                                                                                                                  {
                color: 'lightgray'
            },
            '.Tab':                                                                                                                                                       {
                padding:         '10px 12px 8px 12px',
                border:          'none',
                color:           'white',
                backgroundColor: 'rgba(239, 240, 247, 0.05)',
            },
            '.Tab:hover':                                                                                                                                                 {
                backgroundColor: '#fff',
            },
            '.TabIcon':                                                                                                                                                   {
                fill: "white"
            },
            '.TabIcon--selected':                                                                                                                                         {
                fill: "white"
            },
            '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover':                                                                                                 {
                border:          'none',
                backgroundColor: '#fff',
                boxShadow:       '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
            },
            '.Label':                                                                                                                                                     {
                fontWeight:   '500',
                color:        "white",
                marginBottom: "8px",
                marginTop:    "8px"
            },
            '.PickerItem, .PickerItem:hover, .PickerItem--selected, .PickerItem--selected:hover, .PickerItem--highlight, .PickerItem--highlight:hover, .PickerItem--new': {
                backgroundColor: '#181a26',
                border:          '1px solid rgba(255, 255, 255, 0.5)',
            },
        }
    };

    const options = {
        clientSecret,
        appearance,
    };

    return (
        <>
            {(
             clientSecret && paymentIntentId
             ) && (
                 <Elements  key={props.discount} options={options} stripe={stripePromise}>
                     <CheckoutPaymentStripeElement
                         validateForm={props.validateForm}
                         getBillingValues={props.getBillingValues}
                         paymentIntentId={paymentIntentId}
                         setTermsAccepted={props.setTermsAccepted}
                         termsAccepted={props.termsAccepted}
                     />
                 </Elements>
             )}
        </>
    );
}

export default CheckoutPaymentStripe;