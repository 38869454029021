import React from "react";
import { SECTIONS } from "../constants";

const Section = ({ isMobile }) => (
  <div id="section">
    {SECTIONS.map(({ img, title, description, descriptionNoBR }, index) => (
      <div className="section" key={index}>
        <img className="section-img" src={img}
          loading="lazy"
          decoding="async"
        />
        <div className="section-gradient" />
        <div className={`section-inner sec-${index + 1}`}>
          <h2>{title}</h2>
          {isMobile ? <p>{descriptionNoBR ? descriptionNoBR : description}</p> :
            <p dangerouslySetInnerHTML={{ __html: description }} />
          }
        </div>
      </div>
    ))}
  </div>
)

export default Section;