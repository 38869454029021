import React, {Component} from 'react';
import './js/index';
import './js/style.scss';
import './customPicker.scss'
import {DatesIcon, TravelersIcon} from './Svgs';
import {getCookie, setCookie} from './js/dal/storage';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {DateRange} from 'react-date-range';
import {addDays, endOfDay, format, startOfDay} from 'date-fns';
import locale from 'date-fns/locale/en-US';

export class DatePickerAndGuest
    extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isDateOpen:         false,
            formData:           (
                                    this.props.data ? this.props.data : {
                                        city: '',
                                    }
                                ),
            bookingFormElement: null,
            citiesACOpened:     false,
            cities:             [],
            filteredCities:     [],
        };

        this.search             = this.search.bind(this);
        this.toggleDate         = this.toggleDate.bind(this);
        this.formatDate         = this.formatDate.bind(this);
        this.wrapperRef         = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    formatDate(dateString) {
      const [year, month, day] = dateString.split('-').map(Number);
      const date = new Date(Date.UTC(year, month - 1, day));
   
      const options = { weekday: 'short', month: 'short', day: '2-digit' };
      
    
      return date.toLocaleDateString('en-US', { 
          ...options, 
          timeZone: 'UTC' 
      });
    }

    toggleDate() {
        this.setState((prevState) => (
            {
                isDateOpen: !prevState.isDateOpen,
            }
        ));
    }

    handleDateChange = (ranges) => {
        let {
                startDate,
                endDate
            } = ranges.selected;

        // Ensure we are handling the dates at the start of the day to avoid timezone issues
        startDate = startOfDay(startDate);
        endDate   = endOfDay(endDate);

        if (startDate.getTime() === endDate.getTime()) {
            endDate = addDays(endDate, 1);
        }

        const startDateFormat = format(startDate, 'yyyy-MM-dd');
        const endDateFormat   = format(endDate, 'yyyy-MM-dd');

        const searchParams = new URLSearchParams(window.location.search);

        searchParams.set('checkIn', startDateFormat);
        searchParams.set('checkOut', endDateFormat);

        window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);

        this.search();
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value  = target.type === 'checkbox' ? target.checked : target.value;
        const name   = target.name;

        let {
                formData,
                formErrors,
                data
            } = this.state;

        formData[name]   = value;
        formErrors[name] = null;
        data[name]       = value;

        this.setState({
                          formData:   formData,
                          formErrors: formErrors,
                          data:       data
                      });

        this.props.handleInputChange(event);
    };

    getQueryParameters() {
        const queryParameters = new URLSearchParams(window.location.search);
        const checkIn         = queryParameters.get('checkIn') || getCookie('searchForm-checkIn');
        const checkOut        = queryParameters.get('checkOut') || getCookie('searchForm-checkOut');
        const adults          = parseInt((
                                         queryParameters.get('adults') || getCookie('searchForm-adults')
                                         ) || 2);
        const children        = parseInt((
                                         queryParameters.get('children') || getCookie('searchForm-children')
                                         ) || 0);
        const checkInOut      = {
            from:     (
                          checkIn ? new Date(checkIn.replace(/-/g, '\/')) : new Date(
                              new Date().setDate(new Date().getDate() + 14))
                      ),
            to:       (
                          checkOut ? new Date(checkOut.replace(/-/g, '\/')) : new Date(
                              new Date().setDate(new Date().getDate() + 17))
                      ),
            onChange: (range) => {
                this.search();
            },
        };

        return {
            checkIn,
            checkOut,
            checkInOut,
            adults,
            children
        };
    }

    listAllDates(bookings) {
        let allDates = [];

        bookings.forEach(booking => {
            let currentDate    = new Date(booking.checkIn);
            const checkOutDate = new Date(booking.checkInOut);

            while (currentDate <= checkOutDate) {
                allDates.push(new Date(currentDate));
                currentDate.setDate(currentDate.getDate() + 1);
            }
        });

        return allDates;
    }

    componentDidMount() {
        const {
                  checkIn,
                  checkOut,
                  checkInOut,
                  adults,
                  children
              } = this.getQueryParameters();

        if (checkIn) {
            document.cookie = 'jso-booking-form-1=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.makrealty.com';
        }

        const $form = window.jso.bookingForm('#search_form1', {
            checkInOut:            checkInOut,
            animation:             'fade',
            redirectAfterSubmit:   false,
            storageType:           'cookies',
            storageName:           'jso-booking-form-1',
            cookiesExpiration:     43200,
            countriesAutocomplete: {
                dataURL: '/wp-content/plugins/nd-booking/cities.json',
            },
            guestsSelector:        {
                fields:   {
                    adults: {
                        type:     'buttons',
                        quantity: adults,
                    },

                    children: {
                        type:     'buttons',
                        quantity: children,
                    },
                    age:      null,
                    rooms:    null,
                },
                onChange: (guests) => {
                    this.search();
                },
            },
        });

        this.setState({
                          bookingFormElement: $form,
                      });
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            if (this.state.isDateOpen) {
                this.setState({isDateOpen: false});
            }
        }
    }

    search() {
        let formValue         = this.state.bookingFormElement.bookingForm.getValue();
        const queryParameters = new URLSearchParams(window.location.search);
        formValue             = [
            ...formValue,
            {
                name:  'city',
                value: this.state.formData.city
            },
            {
                name:  'from_date',
                value: queryParameters.get('checkIn'),
            },
            {
                name:  'to_date',
                value: queryParameters.get('checkOut'),
            },
        ];
        this.props.search(formValue);
        this.saveFormData(formValue);
    }

    saveFormData(formValues) {
        setCookie('searchForm-checkIn', formValues[0].value, 1);
        setCookie('searchForm-checkOut', formValues[1].value, 1);
        setCookie('searchForm-adults', formValues[2].value, 1);
        setCookie('searchForm-children', formValues[3].value, 1);
    }

    render() {
        return <div>
            <div>
                {this.props.bookDisabled && <div>
                    This Dates Are Already Reserved
                </div>}
            </div>
            <form method="post" action="#" id="search_form1" autoComplete="off"
                  style={{width: '100%'}}>
                <div className="jso-booking-form-content"
                     style={{
                         display:        'flex',
                         flexDirection:  'column',
                         alignItems:     'center',
                         justifyContent: 'center',
                         gap:            '10px',
                     }}>
                    <div style={{
                        position: 'relative',
                        width:    '100%'
                    }} ref={this.wrapperRef}>
                        <h4 className="jso-booking-form-title">
                            <DatesIcon/>
                            Dates
                        </h4>
                        <div onClick={this.toggleDate} className="jso-booking-check-in-out-content">
                            <div className="jso-date-range-picker-text"
                                 style={{backgroundColor: 'rgba(24, 26, 38, 0.8)'}}>
                                {this.formatDate(this.getQueryParameters().checkIn)}</div>
                            <div className="jso-date-range-picker-text"
                                 style={{backgroundColor: 'rgba(24, 26, 38, 0.8)'}}>
                                {this.formatDate(this.getQueryParameters().checkOut)}</div>
                        </div>
                        {this.state.isDateOpen && <DateRange
                            months={1}
                            ranges={[
                                {
                                    startDate: new Date(new URLSearchParams(window.location.search).get('checkIn')),
                                    endDate:   new Date(new URLSearchParams(window.location.search).get('checkOut')),
                                    key:       'selected',
                                },
                            ]}
                            locale={locale}
                            onChange={(item) => this.handleDateChange(item)}
                            disabledDates={this.listAllDates(this.props.reservation.map((reservation) => (
                                {
                                    checkIn:    reservation.check_in_date,
                                    checkInOut: reservation.check_out_date
                                }
                            )))}
                        />}
                    </div>
                    <div style={{width: '100%'}} className="jso-booking-form-guests" tabIndex="0">
                        <h4 className="jso-booking-form-title">
                            <TravelersIcon/>
                            Travelers
                        </h4>
                        <div className="jso-quantity-selector-fields"/>
                    </div>
                </div>
            </form>
        </div>;
    }

}
