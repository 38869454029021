import React from 'react'
import { EXCLUSIVES } from '../constants'

const Exclusives = () => {
    return (
        <div className='exclusives' id='exclusives'>
            <h2>Concierge <br /> exclusive extras</h2>
            <p className='exclusives-description'>Let us know your desires, and we take care of that</p>
            <div className='photo-wrapper'>
                {EXCLUSIVES.map(({ cn, img, text, title }) => (
                    <div className='photo-card' key={text}>
                        <div className={`photo-card-inner ${cn}`}>
                            <div className='photo-card-inner-imgBox'>
                            <img className='photo-card-inner-imgBox-img' src={img}
                                 loading="lazy"
                                 decoding="async"
                            />
                            </div>
                            <div className='photo-card-inner-textBox'>
                                <p className="photo-card-inner-textBox-title">{title}</p>
                                <p className="photo-card-inner-textBox-text">{text}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Exclusives