import React, {Component} from 'react';
import {Col, Offcanvas, Row} from "react-bootstrap";
import './Header.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faGear, faPhoneAlt, faUser} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

export class ManagerHeader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showMobileMenu:     false,
            userDropdownHidden: true,
            showModal:          false
        }

        this.toggleMobileMenu   = this.toggleMobileMenu.bind(this);
        this.toggleUserDropdown = this.toggleUserDropdown.bind(this);
        this.toggleModal        = this.toggleModal.bind(this);
    }

    toggleMobileMenu() {
        this.setState({showMobileMenu: !this.state.showMobileMenu});
    }

    toggleUserDropdown() {
        if (this.state.userDropdownHidden) this.setState({userDropdownHidden: false}); else this.setState(
            {userDropdownHidden: true});
    }

    toggleModal() {
        this.setState({showModal: !this.state.showModal});
    }

    toggleUserback() {
        let element = document.getElementsByClassName("userback-button");
        element[0].click();
    }

    render() {

        const {user} = this.props;

        return (
            <>

                <Offcanvas show={this.state.showMobileMenu} onHide={this.toggleMobileMenu}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title> <a href={"https://makrealty.com/"}><img
                            src="https://makrealty.com/wp-content/uploads/2022/05/MAK_Logo_Ver5_Dark_CMYK.png"
                            alt="" className={"logo"}/></a></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <a href={"https://makrealty.com/"}>Home</a>

                        <Link to={'/manager/listings'}>Listings</Link>
                        <Link to={'/manager/pending-bookings'}>Pending bookings</Link>

                        <div className={"d-block mt-4 text-start"}>
                            <p className={"m-0 d-block"}>Hi, {user ? user.first_name : "guest"}!</p>
                            {user && <Link className={"text-primary"} to={"/logout"}>Logout</Link>}
                            {!user && <><Link className={"text-primary"} to={"/login"}>Login</Link> / <Link
                                className={"text-secondary"} to={"/register"}>Register</Link></>}
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>

                <Row className={"header container justify-content-between"}>
                    <Col xs={6} md={6} lg={"auto"} className='header-logo-column'>
                        <a href={"https://makrealty.com/"}><img
                            src="https://makrealty.com/wp-content/uploads/2022/05/MAK_Logo_Ver5_Dark_CMYK.png"
                            alt="" className={"logo"}/></a>
                    </Col>
                    <Col lg={"auto"} className={"d-none d-lg-flex justify-content-center links"}>
                        <a className={"me-5"} href={"https://makrealty.com/"}>Home</a>

                        <Link className='me-5' to={'/manager/listings'}>Listings</Link>
                        <Link className='me-5' to={'/manager/pending-bookings'}>Pending bookings</Link>

                    </Col>
                    <Col xs={6} md={6} lg={"auto"} className={"d-flex align-items-center justify-content-end header-menu-column"}>

                        <FontAwesomeIcon icon={faBars} onClick={this.toggleMobileMenu} className={"d-lg-none me-4"}/>

                        {user && user.role == 'admin' && <Link className='me-4' to={"/manager/listings"}><FontAwesomeIcon icon={faGear}/></Link>}

                        <a href={"tel:1-(305)-204-6049"} className={"me-4"}><FontAwesomeIcon icon={faPhoneAlt}/></a>

                        <div className={"d-flex align-items-center p-0"}>
                            <Link to={"/account"}><FontAwesomeIcon icon={faUser}/></Link>
                            <div className={"d-none d-lg-block text-start"}>
                                <p className={"m-0 d-block"}>Hi, {user ? user.first_name : "guest"}!</p>
                                {user && <Link className={"text-primary"} to={"/logout"}>Logout</Link>}
                                {!user && <><Link className={"text-primary"} to={"/login"}>Login</Link> / <Link
                                    className={"text-secondary"} to={"/register"}>Register</Link></>}
                            </div>
                        </div>

                    </Col>
                </Row>
            </>
        )
    }

}