import React, {Component} from 'react';
import {Button, Col, Container, Form, FormControl, FormGroup, FormLabel, Row, Spinner} from "react-bootstrap";
import {toast} from "react-hot-toast";
import Api from "../../Api";
import {Link, Redirect} from "react-router-dom";
import {FormError} from "../../components/Forms/Inputs/FormError";

export class Login
    extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formData:          [],
            formErrors:        [],
            formProcessing:    false,
            formSuccess:       false,
            show2fa:           false,
            showResetPassword: false,
        }

        this.handleSubmit        = this.handleSubmit.bind(this);
        this.handleInputChange   = this.handleInputChange.bind(this);
        this.toggleResetPassword = this.toggleResetPassword.bind(this);
    }

    toggleResetPassword() {
        this.setState({showResetPassword: !this.state.showResetPassword});
    }

    handleInputChange(event) {
        const target = event.target;
        const value  = target.type === 'checkbox' ? target.checked : target.value;
        const name   = target.name;

        let {
                formData   = [],
                formErrors = [],
                data       = []
            } = this.state;

        formData[name]   = value;
        formErrors[name] = null;
        data[name]       = value;

        this.setState({
                          formData:   formData,
                          formErrors: formErrors,
                          data:       data
                      })
    }

    handleSubmit(event) {

        event.preventDefault();

        let formData = new FormData();
        for (const key in
            this.state.formData) formData.append(key, this.state.formData[key]);
        this.setState({formProcessing: true});

        if (this.state.showResetPassword) {

            let loadingToast = toast.loading("Resetting");

            Api.post('/forgot-password', formData).then((response) => {

                   if (response) toast.success("Password reset link sent to your email.", {id: loadingToast});

               })
               .catch((error) => {

                   if (error.response.data.errors) this.setState({formErrors: error.response.data.errors});
                   toast.error(error.response.data.message, {id: loadingToast});

               })
               .finally((response) => {

                   this.setState({formProcessing: false});

               });

        } else if (this.props.token) {

            let loadingToast = toast.loading("Saving");

            formData.append('token', this.props.token);

            Api.post('/reset-password', formData).then((response) => {

                   toast.success("Password reset. You may now log in.", {id: loadingToast});
                   this.setState({formSuccess: true});

               })
               .catch((error) => {

                   if (error.response.data.errors) this.setState({formErrors: error.response.data.errors});
                   toast.error(error.response.data.message, {id: loadingToast});
                   if (error.response.data.show2fa) this.setState({show2fa: error.response.data.show2fa});

               })
               .finally((response) => {
                   this.setState({formProcessing: false});
               });

        } else {

            let loadingToast = toast.loading("Signing In");

            Api.get('/sanctum/csrf-cookie').then((response) => {

                Api.post('/login', formData)
                   .then((response) => {
                       // console.log(response);
                       toast.success("You have successfully logged in.", {id: loadingToast});
                       this.props.updateFunction();
                   })
                   .catch((error) => {

                       if (error.response.data.errors) this.setState({formErrors: error.response.data.errors});
                       toast.error(error.response.data.message, {id: loadingToast});
                       if (error.response.data.show2fa) this.setState({show2fa: error.response.data.show2fa});

                   })
                   .finally((response) => {
                       this.setState({formProcessing: false});
                   });

            });

        }
    }

    render() {

        if (this.props.user) {
            return <Redirect
                to={"/"}/>
        } else if (this.props.token && this.state.formSuccess) {
            return <Redirect
                to={"/login"}/>
        } else {
            return (
                <Container>

                    <Row>
                        <Col>
                            <h1 className={"page-title"}>Sign In</h1>
                        </Col>
                    </Row>

                    <Row className="loginPage m-auto" style={{maxWidth: "560px"}}>
                        <Col md={12} className={"p-0"}>
                            {/*<div className="loginLogo">*/}
                            {/*    <img src={logoWhite} alt={""}/>*/}
                            {/*</div>*/}
                            <div className="loginFormContainer align-middle">
                                <h6 className={"mb-4"}>Please enter your login information below.</h6>
                                <Form onSubmit={this.handleSubmit}>

                                    {this.state.showResetPassword && <p className={"mb-4"}>We will send you a temporary
                                        link to
                                        reset your password.</p>}

                                    {!this.state.show2fa && <FormGroup className="mb-4">
                                        <FormLabel>Email</FormLabel>
                                        <FormControl
                                            type="text"
                                            name="email"
                                            isInvalid={(
                                     this.state.formErrors && this.state.formErrors.email
                                            )}
                                            placeholder="Enter email"
                                            onChange={this.handleInputChange}
                                        />
                                        <FormError error={this.state.formErrors.email}/>
                                    </FormGroup>}

                                    {this.props.token && <><Row className={"mb-4"}>
                                        <Col>
                                            <FormLabel>New Password</FormLabel>
                                            <FormControl
                                                autocomplete={"new-password"}
                                                name={"password"}
                                                type={"password"}
                                                onChange={this.handleInputChange}
                                                isInvalid={(
                                     this.state.formErrors && this.state.formErrors.password
                                                )}
                                            />
                                            <FormError error={this.state.formErrors.password}/>
                                        </Col>
                                    </Row>
                                        <Row className={"mb-4"}>
                                            <Col>
                                                <FormLabel>Confirm Password</FormLabel>
                                                <FormControl
                                                    autocomplete={"new-password"}
                                                    name={"password_confirmation"}
                                                    type={"password"}
                                                    onChange={this.handleInputChange}
                                                    isInvalid={(
                                     this.state.formErrors && this.state.formErrors.password_confirmation
                                                    )}
                                                />
                                                <FormError error={this.state.formErrors.password_confirmation}/>
                                            </Col>
                                        </Row></>}

                                    {!this.state.show2fa && !this.state.showResetPassword && !this.props.token && <>
                                        <FormGroup className="mb-4">
                                            <FormLabel>Password</FormLabel>
                                            <FormControl
                                                type="password"
                                                name="password"
                                                isInvalid={(
                                     this.state.formErrors && this.state.formErrors.password
                                                )}
                                                placeholder="Enter password"
                                                onChange={this.handleInputChange}
                                            />
                                            <FormError error={this.state.formErrors.password}/>
                                        </FormGroup>
                                        <Row className={"my-4"}>
                                            <Col className={"d-flex align-items-center"}>
                                                <FormGroup>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="remember-me"
                                                        name="remember"
                                                        label="Remember me"
                                                        onChange={this.handleInputChange}
                                                    />
                                                </FormGroup>
                                                <span className={"ms-auto cursor-pointer text-primary"}
                                                      onClick={this.toggleResetPassword}>Forgot Password</span>
                                            </Col>
                                        </Row>
                                    </>}

                                    {this.state.show2fa && <FormGroup className="mb-4">
                                        <FormLabel>Two Factor Code</FormLabel>
                                        <FormControl
                                            type="text"
                                            name="two_factor_code"
                                            isInvalid={(
                                     this.state.formErrors && this.state.formErrors.two_factor_code
                                            )}
                                            placeholder="######"
                                            onChange={this.handleInputChange}
                                        />
                                        <FormError error={this.state.formErrors.two_factor_code}/>
                                    </FormGroup>}

                                    <Row>

                                        <Col>
                                            <Button
                                                size="md"
                                                variant="primary"
                                                type="submit"
                                                className="m-auto mb-3 w-100"
                                                disabled={this.state.formProcessing}
                                            >
                                                {this.state.formProcessing && this.props.token ? <><Spinner size={"sm"}
                                                                                                            className={"me-2"}
                                                                                                            animation={"border"}/> Signing
                                                    In</> : (
                                                     this.state.formProcessing ? <><Spinner size={"sm"}
                                                                                            className={"me-2"}
                                                                                            animation={"border"}/> Signing
                                                         In</> : (
                                                         (
                                                             this.state.showResetPassword || this.props.token
                                                         ) ? "Reset" : "Sign In"
                                                     )
                                                 )}
                                            </Button>


                                            {!this.state.showResetPassword && <Col><Link
                                                to={"/register"}
                                                className={"w-100 m-auto btn-md btn-secondary btn"}>
                                                Register
                                            </Link></Col>}

                                            {this.state.showResetPassword && <Button
                                                onClick={this.toggleResetPassword}
                                                className={"w-100 m-auto btn-md btn-secondary btn"}>
                                                Back
                                            </Button>}

                                        </Col>

                                    </Row>

                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            )
        }
    }
}