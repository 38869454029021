import React, {Component} from 'react';
import {Alert, Col, Container, Form, Row} from "react-bootstrap";
import {Datepicker} from "../../components/Forms/Datepicker/Datepicker";
import {SearchResults} from "../../components/Lists/SearchResults";
import debounce from "lodash/debounce";
import Api from "../../Api";
import {toast} from "react-hot-toast";
import './Search.scss';
import {Loader} from "../../components/Loader/Loader";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

export class Search
    extends Component {


    constructor(props) {
        super(props)
        const queryParams = new URLSearchParams(window.location.search);
        let orderByDir       = queryParams.get('orderByDir')
   
        this.state = {
            tableData:               [],
            formData:                [],
            page:                    1,
            pageSize:                100,
            totalSize:               0,
            orderByCol:              "listing_exceptions_sum_price",
            orderByDir:              orderByDir ?? "asc",
            showModal:               false,
            showModalData:           [],
            showSearch:              false,
            search:                  null,
            redirectLink:            null,
            bookingFormEl:           null,
            showListingDetailsModal: false,
            showListingDetailsData:  [],
            selectedCity:            '',
        }

        this.fetch                     = this.fetch.bind(this);
        this.handleSearch              = debounce(this.handleSearch.bind(this), 500);
        this.handleSortChange          = this.handleSortChange.bind(this);
        this.updateBookingFormEl       = this.updateBookingFormEl.bind(this);
        this.toggleListingDetailsModal = this.toggleListingDetailsModal.bind(this);

    }

    updateBookingFormEl(
        el,
        city
    ) {

        this.setState({
                          bookingFormEl: el
                      })
        const fields = el.bookingForm.getValue()

        fields.push({
                        name:  "city",
                        value: city,
                    })

        this.setState({city: city})

        this.fetch(fields)
    }

    fetch(inputs) {

        this.setState({
                          loading: true
                      });

        let {
                filter,
                selectedTab
            } = this.props;
        let {
                page,
                pageSize,
                search
            } = this.state;

        let axiosUrl = '/api/listings/get_available?orderByCol=' + this.state.orderByCol + '&orderByDir=' + this.state.orderByDir + '&page_size=' + pageSize + '&page=' + page;
        axiosUrl += '&exact[status]=' + (
            selectedTab === "archived" ? "archived" : "active"
        );
        if (filter) axiosUrl += '&exact[rental_type]=' + filter;
        if (search) axiosUrl += '&search=' + search;

        if (inputs) {

            let checkIn;
            let checkOut;
            let adults;
            let children;
            let city;

            for (
                let i = 0;
                i < inputs.length;
                i++
            ) {
                var value = inputs[i].value;
                if (inputs[i].name === "from_date") {
                    checkIn = value;
                    axiosUrl += '&checkIn=' + checkIn;
                }
                if (inputs[i].name === "to_date") {
                    checkOut = value;
                    axiosUrl += '&checkOut=' + checkOut;
                }
                if (inputs[i].name === "adults") {
                    adults = value;
                    axiosUrl += '&adults=' + adults;
                }
                if (inputs[i].name === "children") {
                    children = value;
                    axiosUrl += '&children=' + children;
                }
                if (inputs[i].name === "city") {
                    city = value;
                    axiosUrl += '&city=' + city;
                }
            }

            this.setState({
                              formData:     {
                                  checkIn:  checkIn,
                                  checkOut: checkOut,
                                  adults:   adults,
                                  children: children,
                                  city:     city
                              },
                              selectedCity: city
                          });

            window.history.replaceState(null, null,
                                        "?checkIn=" + checkIn + "&checkOut=" + checkOut + "&adults=" + adults + "&children=" + children + "&city=" + city + window.location.hash
            );

        }

        // Make a request for a user with a given ID
        Api.get(axiosUrl)
           .then(response => {
               // handle success
               this.setState({
                                 tableData: response.data.data,
                                 totalSize: response.data.total,
                                 loading:   false
                             }, () => {

                   // //   If hash, open listing.
              

               });


           })
           .catch(function (error) {
               // handle error
               toast.error(error.response.data.message);
               console.log(error);
           })
    }

    handleSearch(event) {
        let value = event.target.value;
        this.setState({
                          search: value,
                          page:   1
                      }, () => {
            this.fetch();
        });

    }

    handleSortChange(event) {

        let value = event.target.value;
        this.setState({
                          orderByCol: value.split("-")[0],
                          orderByDir: value.split("-")[1]
                      }, () => {
            const fields = this.state.bookingFormEl.bookingForm.getValue()
            fields.push({
                            name:  "city",
                            value: this.state.selectedCity
                        })
            this.fetch(fields);
        });


    }

    toggleListingDetailsModal(
        event = null,
        id    = null
    ) {

        const {formData} = this.state;

        var newData = [];

        if (event && event.currentTarget) {
            // console.log(event.currentTarget);
            id = (
                event.currentTarget.getAttribute('data-id') ? event.currentTarget.getAttribute(
                    'data-id') : event.currentTarget.getAttribute('id')
            );
        }

        // console.log(id);

        for (const key in
            this.state.tableData) {

            console.log("test");
            let thisRow = this.state.tableData[key];
            console.log(thisRow);
            if (thisRow.id === id) {
                newData = thisRow;
            }

        }

        this.setState({
                          showListingDetailsData:  newData,
                          showListingDetailsModal: !this.state.showListingDetailsModal
                      });

        window.history.replaceState(null, null,
                                    "?checkIn=" + formData.checkIn + "&checkOut=" + formData.checkOut + "&adults=" + formData.adults + "&children=" + formData.children + "#" + (
                                                    id ? id : ""
                                                )
        );

    }

    componentDidMount() {
        window.fbq('track', 'PageView');
    }

    render() {
        return <>
            <Container>
                <Row>
                    <Col className="heading-bc">
                        <div className="breadcrumbs">
                            <Breadcrumbs children={[
                                {
                                    text: 'Search Listing',
                                    url:  '/'
                                }
                            ]}/>
                        </div>
                        <h1 className={"page-title"}>Book Now</h1>
                    </Col>
                </Row>

                <Datepicker
                    handleInputChange={this.handleInputChange}
                    updateBookingFormEl={this.updateBookingFormEl}
                    search={this.fetch}
                />

                <div className="my-4"/>

                <Row>

                    <Col className={"text-white fs-5 d-flex align-items-center"}>
                        <b className={"me-2 fs-4"}>{this.state.tableData.length}</b> listings available

                        <div className={"d-md-none my-5"}/>

                    </Col>
                    <Col xs={12} md={"auto"}>
                        <Form.Select onChange={this.handleSortChange} 
                            value={
                                this.state.orderByDir === "desc"
                                && "listing_exceptions_sum_price-desc"
                            }
                        >
                            <option value={"listing_exceptions_sum_price-asc"}>Price per night: low to high</option>
                            <option value={"listing_exceptions_sum_price-desc"}>Price per night: high to low</option>
                            <option value={"square_feet-asc"}>Square feet: low to high</option>
                            <option value={"square_feet-desc"}>Square feet: high to low</option>
                        </Form.Select>
                    </Col>

                </Row>

                <div className="my-4"/>

                {/*{this.state.loading && <div className={"p-4 text-center"}><Spinner animation={"border"}/></div>}*/}
                {this.state.loading && <Loader/>}
                {!this.state.loading && !this.state.tableData.length && <Alert>Sorry, we could not find listings for
                    your search criteria. Please either try a different search, or contact us at 1-(305)-204-6049 for
                    more options.</Alert>}
                {this.state.tableData.length > 0 && <SearchResults
                    checkIn={this.state.formData.checkIn}
                    checkOut={this.state.formData.checkOut}
                    adults={this.state.formData.adults}
                    children={this.state.formData.children}
                    tableData={this.state.tableData}
                    toggleFunction={this.toggleListingDetailsModal}
                    user={this.props.user}
                />}

            </Container>
        </>
    }

}