import React from "react";

export default function StarRating({ rating, starIcon, starSize, className, style }) {
    const stars_html = [];

    let stars = 0
    while (stars < 5) {
        if (Math.round(rating) >= stars + 1) {
            stars_html.push(<img alt="" src={starIcon} width={starSize} className={"mx-1"} />);
        } else {
            stars_html.push(<img alt="" src={starIcon} width={starSize} className={"mx-1 opacity-50"} />);
        }

        stars++
    }

    return (
        <span className={className} style={style}>
            {stars_html}
        </span>
    );
}